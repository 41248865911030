body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: 'Poppins';
}

.col {
  width: auto;
}

.apiData {
  width: 100vw;
  overflow-y: scroll;
}

/* MENU HEADER CSS */
.navbar {
    background: linear-gradient(55deg, #6948BA 10%, #A93284 70%, #D1306C);
    min-height: 80px;
}

.navbar-nav {
    margin-right: 30px;
}

.nav-logo {
    margin-left: 10px;
}

.navbar-brand > a:visited, .navbar-brand > a:hover, .navbar-brand-two > a:visited, .navbar-brand-two > a:hover {
    text-decoration: none;
}

.navbar-brand .nav-logo-xl {
    width: 120px;
    margin-left: 28%;
}

.navbar-brand-two .nav-logo-xs {
    width: 15%;
}

.navbar-collapse, .navbar-nav, .nav, .nav-account {
    min-height: 80px;
}

.navbar-nav > li > a {
    color: white;
    padding: 0.5rem;
    font-size: 14px;
    font-weight: 400;
}
.navbar-nav > li > a:hover {
  text-shadow: 0 0 0.01px #fff;
}

.navbar-toggle .icon-bar {
    background: #000 none repeat scroll 0 0;
}

a.nav-link {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 14px;
}

.nav-index:hover, .ember-power-select-trigger:hover {
    color: white;
    border-bottom: 2px solid white;
}

a.nav-main.nav-link.menu-item.nav-active {
    color: white;
    border-bottom: 2px solid white;
}

.navbar-toggler .navbar-toggler-icon {
    border: 0px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.badge, .beta-badge {
    margin-left: 5px;
    background-color: #00A1D3;
    color : #FFFFFF;
    padding: 5px 8px 5px 8px;;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
}


/* FOOTER CSS */
.footer {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  min-height: 50vh;
  color: #F1F5F6;
  background:linear-gradient(55deg, #6948BA 10%, #A93284 70%, #D1306C);
  padding-top: 3.5rem;
  padding-left: 5%;
  padding-right: 5%;
}
.logoLink {
  position:relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.footer-logo {
  height: auto;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.allRights {
  font-size: 14px;
  color:
}

.socialBtn {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  padding-left: 0;
  padding-right: 0;
}
.socialBtn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.socialImg {
  max-width: 60px;
  height: auto;
}

.list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.listTitle {
  padding-bottom: 1em;
  opacity: 0.7;
}
.listItem {
  font-size: 0.9em;
  padding-bottom: 0.5em;
  color:inherit;
}
.listItem:hover {
  text-decoration: none;
  color:inherit;
  text-shadow: 0 0 0.01px #fff;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .containerFooter {
    background-color: transparent;
    text-align: center;
    margin-top: 1em;
  }

  .list {
    -webkit-align-items: center !important;
            align-items: center !important;
  }

  .socialBlock {
    margin-top: 4%;
  }

  .pool {
    margin-top: 4%;
    -webkit-order: 3;
            order: 3;
  }
  .services {
    -webkit-order: 2;
            order: 2;
  }
  .about {
    -webkit-order: 1;
            order: 1;
  }
  .focus {
    margin-top: 4%;
    -webkit-order: 4;
            order: 4;
  }
}

